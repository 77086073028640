<template>
  <div class="main">
    <van-nav-bar :title="t('login.welcome')" :border="false">
      <template #left>
        <NewkiSvg name="cross" color="#64748b" @click="goBack" />
      </template>
      <template #right>
        <span class="help">{{ t('common.help') }}</span>
      </template>
    </van-nav-bar>
    <div class="logo">
      <img src="~/assets/images/login/newki.svg" width="105" alt="logo" />
    </div>
    <slot />

    <NewkiLineSvg class="bottom-line" />
  </div>
</template>

<script setup lang="ts">
import NewkiLineSvg from '~/components/NewkiLineSvg.vue'
const { t } = useI18n()

const goBack = () => {
  routerBack()
}
</script>

<style scoped lang="scss">
.main {
  min-height: 100vh;
  background-color: $white;
}
.help {
  color: $text-color-assist;
}
.logo {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}
.bottom-line {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
